import './Start.css';
import Header from './Header.js';
import Csv from './Csv.js';
import Picker from './picker.tsx';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD7HVgbOMcqeE4s7xrRs4UqBaw49gaEWv4",
  authDomain: "heisenworth-gg.firebaseapp.com",
  projectId: "heisenworth-gg",
  storageBucket: "heisenworth-gg.appspot.com",
  messagingSenderId: "968769784628",
  appId: "1:968769784628:web:ba06b6b6815816ecb6b361",
  measurementId: "G-VS44VHD6BN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function Start() {
  return (
    <>
      <Header />
      <Picker />
    </> 
  );
}

export default Start;
