import logo from './marble.png';
import mrk from './mrkMarble3x.webp'
import pepe from './pepeMarble4x.webp';
import './Header.css';

function Header() {
  return (
    <div className="Header">
      <header className="Header-header">
        <img src={pepe} className="Pepe-logo" alt="pepe" />
        <p className="Header-left">Marbler Leaderboard</p>
        <img src={mrk} className="Marble-logo" alt="logo" />       
      </header>
    </div>
  );
}

export default Header;
