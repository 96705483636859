import "./Table.css";
import { useState } from "react";
import Papa from "papaparse";

function Table(data) {
  // State to store parsed data
  // const [parsedData, setParsedData] = useState([]);

  //State to store table Column name
  // const [tableCols, setTableCols] = useState([]);

  //State to store the values
  const [values, setValues] = useState([]);

  const changeHandler = (event) => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(data, {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const colsArray = [];
        const valuesArray = [];

        // Iterating data to get column name and their values
        results.data.map((d) => {
          colsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        // Parsed Data Response in array format
        // setParsedData(results.data);

        // Filtered Column Names
        // setTableCols(colsArray[0]);

        // Filtered Values
        setValues(valuesArray);
      },
    });
  };

  return (
    <div className="Table">
      <button
        onClick={changeHandler}
        style={{ display: "block", margin: "20px auto" }}
      >Load Data</button>
      {/* Table */}
      <table>
        <thead>
          <tr>
            <th>Rank</th>
            <th>Marbler</th>
            <th>Points</th>
            <th>Wins</th>
            <th>Eliminations</th>
            <th>Races</th>
          </tr>
        </thead>
        <tbody>
          {values.map((value, index) => {
            let link = 'https://twitch.tv/' + value[0]
            let place = index + 1
            return (
              <tr key={index} className={value[1]}>
                <td className={place}>{place}</td>
                <td style={{ color: '#' + value[1] }}>
                  <a href={link} target="_blank" rel="noreferrer">{value[0]}</a>
                </td>
                <td>{value[3]}</td>
                <td>{value[2]}</td>
                <td>{value[5]}</td>
                <td>{value[6]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default Table;